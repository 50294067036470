import React, { useCallback, useEffect, useMemo, useState} from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_ALL_ITEMS, GET_USER_TAGS, GET_ITEM_TAGS, GET_FAVORITES, GET_WEEK_MONTH_GROUPED_SNACK_SHIPMENTS, GET_DIVISIONS, GET_WEEK_MONTH_GROUPED_SNACK_PREDS} from "../../queries";
import { Modal, Row, Col, Segmented, Button, Select, Form, DatePicker, Layout, Cascader, Spin, Space, Tag, Tooltip } from "antd";
import { ExportOutlined, StarFilled, StarOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { addMonths, format } from "date-fns";
import "./SnackMonthly.less";
import PredictionsChart from "./PredictionsChart";
import moment from "moment";
import * as XLSX from 'xlsx'
import { filterRowsByQueryAndFilterParams } from "../../Utilities/listFilter";
import { useParams } from "react-router-dom";

const salesDeptOrder = ['社内販売（本部）', '北海道東北統括部', '首都圏統括部', '中部統括部', '近畿中四国統括部', '九州統括部', '広域営業部', '輸出営業部'];
const salesDeptSorter = (a, b) => {
	// always sort '合計' to end
	
	if (a === "合計") {
		return 1;
	}
	if (b === "合計") {
		return -1;
	}
	if (salesDeptOrder.indexOf(a) === -1 ) {
		return 1;
	}
	if (salesDeptOrder.indexOf(b) === -1 ) {
		return -1;
	}
	return salesDeptOrder.indexOf(a) - salesDeptOrder.indexOf(b);
}

const SnackMonthly = () => {
	const [tableGraphMode, setTableGraphMode] = useState("table");
	const [divisions, setDivisions] = useState([]);
	const [selectedDivisions, setSelectedDivisions] = useState([]);
	const [snackShipments, setSnackShipments] = useState({});
	const [preds, setPreds] = useState([]);	
	const [groupBy, setGroupBy] = useState("month");
	const [selectedTags, setSelectedTags] = useState([]);
	const [filterFavorites, setFilterFavorites] = useState(false);
	const [subdivision, setSubdivision] = useState("sales_department_abbreviation");
	const [dateRange, setDateRange] = useState([]);
	const [rows, setRows] = useState([]);
	const [originalRows, setOriginalRows] = useState([]);
	const [searchText, setSearchText] = useState("");
	
	const { itemNameId } = useParams();
	
	const [form] = Form.useForm();

	const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
	
	const { data: rawData, loading: allItemsLoading } = useQuery(GET_ALL_ITEMS, { errorPolicy: "all", notifyOnNetworkStatusChange: true });
	
	const { data: tags } = useQuery(GET_USER_TAGS, { variables: { userId: currentUser.id } });

    const {data: itemTags } = useQuery(GET_ITEM_TAGS, {variables: {userId: currentUser.id}, fetchPolicy: 'network-only'});

	const { data: favorites } = useQuery(GET_FAVORITES, { variables: { userId: currentUser.id } });
	
	useEffect(() => {
        if (rawData) {
			const data = JSON.parse(rawData.allItems);
            const parsedRows = data.map(
                itemName => {
                    const latestCycle = itemName.item_selling_cycles && itemName.item_selling_cycles.length > 0 ? 
                        itemName.item_selling_cycles.reduce((prev, curr) => curr.selling_end_dt > prev?.selling_end_dt ? curr : prev) : null
                    const profile = itemName.item_profile || null
                    const status = itemName.user_defined_status ? itemName.user_defined_status : itemName.status;
                    const isFavorite = favorites?.favorites?.map(favorite => parseInt(favorite.itemNameId)).includes(parseInt(itemName.id));
                    const tags = itemTags?.itemTags.filter(itemTag => parseInt(itemTag.itemName.id) === itemName.id)
                    const formattedTags = tags?.map(t => ({id: t.id, label: t.tag.name, value: t.tag.color, tagId: t.tag.id}))
                    return {
						...itemName,
                        key: itemName.id,
						id: itemName.id,
                        name: itemName.item_name,
                        nameInfo: { itemNameId: itemName.id, name: itemName.item_name },
                        description: profile?.item_detail,
                        image: { url: profile?.download_url || "no url", name: profile?.item_img_file || "image.png"},
                        products: itemName.item_mappings,
                        hidden: itemName.hidden,
                        status,
                        size: itemName.item_size || '',
                        updated_at: itemName.updated_at,
                        updated_by: itemName.updated_by_user?.email || '',
                        selling_period: itemName.item_selling_cycles && itemName.item_selling_cycles.length > 0 ? {
                            period: `${latestCycle.selling_start_dt.split('T')[0]} - ${latestCycle.selling_end_dt.split('T')[0]}`,
                            start: latestCycle.selling_start_dt.split('T')[0],
                            end: latestCycle.selling_end_dt.split('T')[0],
                        } : null,
                        latestCycle: latestCycle,
                        latest_price: latestCycle?.regular_price,
                        favorite: isFavorite,
                        tags: formattedTags,
                        productCodes: itemName.item_mappings?.map(e => e.prod_id),
						latestProductCode: Math.max(...itemName.item_mappings.map(e => e.prod_id)),
                    }
                });
                setRows(parsedRows);
                setOriginalRows(parsedRows);
        }
    }, [rawData, favorites, itemTags]);
	
	useEffect(() => {
        setRows(filterRowsByQueryAndFilterParams(originalRows, searchText, filterFavorites, null, selectedTags))
    }, [originalRows, searchText, filterFavorites, setRows, selectedTags]);
	
    const formattedTags = useMemo(() => {
        if (tags) {
            return tags.tags.map(tag => {
                return {
                    label: tag.name,
                    value: tag.color,
                    id: tag.id
                }
            })
        }
        return []
    }, [tags])
	
    const allItems = useMemo(() => {
        if (rawData) {
            return JSON.parse(rawData.allItems).sort((a, b) => {
				return a.id - b.id;
			})
        }
        return [];
    }, [rawData]);
	
	const itemSorter = (a, b) => {
		// sort by latest prod_id
		const itemInfoA = allItems.find((i) => i.id === parseInt(a))
		const itemInfoB = allItems.find((i) => i.id === parseInt(b))
		
		const latestProdIdA = itemInfoA.item_name_prod_id?.most_recent_prod_id;
		const latestProdIdB = itemInfoB.item_name_prod_id?.most_recent_prod_id;
		
		if (!latestProdIdA) {
			return -1;
		}
		if (!latestProdIdB) {
			return 1;
		}
		
		return parseInt(latestProdIdA) - parseInt(latestProdIdB);
	}
	
	const [getSnackShipments2, { loading: shipmentsLoading}] = useLazyQuery(GET_WEEK_MONTH_GROUPED_SNACK_SHIPMENTS,
		{
			fetchPolicy: "network-only",
			onCompleted: (data) => {
				setSnackShipments(data.weekMonthGroupedSnackShipments);
			},
		}
	)
	
	const [getSnackPreds2] = useLazyQuery(GET_WEEK_MONTH_GROUPED_SNACK_PREDS, {
		fetchPolicy: "network-only",
		onCompleted: (data) => {
			setPreds(data.weekMonthGroupedSnackPreds);
		}
	})
	
	const tableData = useMemo(() => {
		// merge shipments and preds
		// structure for both is { item_id: { division: { date: count } } }
		// new structure is { item_id: { division: { date: { shipment: count, pred: count } } } }
		
		const data = {};
		Object.keys(snackShipments).forEach((item) => {
			data[item] = {};
			Object.keys(snackShipments[item]).forEach((division) => {
				data[item][division] = {};
				Object.keys(snackShipments[item][division]).forEach((date) => {
					data[item][division][date] = { shipment: snackShipments[item][division][date] };
				});
			});
		});
		
		Object.keys(preds).forEach((item) => {
			if (!data[item]) {
				data[item] = {};
			}
			Object.keys(preds[item]).forEach((division) => {
				if (!data[item][division]) {		
					data[item][division] = {};
				}
			})
			Object.keys(preds[item]).forEach((division) => {
				Object.keys(preds[item][division]).forEach((date) => {
					if (!data[item][division][date]) {
						data[item][division][date] = { shipment: 0 };
					}
					data[item][division][date].pred = preds[item][division][date];
				});
			});
		})
		
		return data
	}, [snackShipments, preds]);
	
	const [getDivisions] = useLazyQuery(GET_DIVISIONS, {
		onCompleted: (data) => {
			const newDivisions = []
			data.divisions.forEach((division) => {
				const [ department, branch, company, destination ] = division;
				if (!newDivisions[department]) {
					newDivisions[department] = {};
				}
				
				if (!newDivisions[department][branch]) {
					newDivisions[department][branch] = {};
				}
				
				if (!newDivisions[department][branch][company]) {
					newDivisions[department][branch][company] = {};
				}
				
				if (!newDivisions[department][branch][company][destination]) {
					newDivisions[department][branch][company][destination] = true;
				}
			});
			
			const divisionsOptions = Object.keys(newDivisions).map((department) => {
				return {
					label: department,
					value: department,
					children: Object.keys(newDivisions[department]).map((branch) => {
						return {
							label: branch,
							value: branch,
							children: Object.keys(newDivisions[department][branch]).map((company) => {
								return {
									label: company,
									value: company,
									children: Object.keys(newDivisions[department][branch][company]).map((destination) => {
										return {
											label: destination,
											value: destination,
										};
									}),
								};
							}),
						};
					}),
				};
			});
			
			setDivisions(divisionsOptions);
		}
	})
	
	const months = useMemo(() => {
		let latestMonth = new Date();
		
		if (Object.keys(snackShipments).length > 0 && Object.values(Object.values(snackShipments)[0]).length > 0) {
			const latestMonthString = Object.keys(Object.values(Object.values(snackShipments)[0])[0]).at(-1);
			const isValidDate = !isNaN(new Date(latestMonthString).getTime());
			latestMonth = isValidDate ? new Date(latestMonthString) : latestMonth;
		}
		return [
			// one month before current and 2 after
			addMonths(latestMonth, -1),
			latestMonth,
			addMonths(latestMonth, 1),
			addMonths(latestMonth, 2),
		];
	}, [snackShipments]);
	
	const groupByWeekOrMonth = (data, groupBy) => {
		const grouped = {};
		// if grouping by week, data is summed with key as first day of that week
		// if grouping by month, data is summed with key as first day of that month
		if (groupBy === "day") return data;
		
		Object.keys(data).forEach((date) => {
			const dateObj = new Date(date);
			const firstDayOfMonth = moment(dateObj).startOf("month").format("YYYY-MM-DD")
			const firstDayOfWeek = moment(dateObj).startOf("week").format("YYYY-MM-DD")
			const key = groupBy === "week" ? firstDayOfWeek : firstDayOfMonth;
			const current = grouped[key] || 0;
			grouped[key] = current + data[date];
		});
		
		return grouped;	
	}
	
	const chartShipments = useMemo(() => {
		// format needs to be { "2024-01-01": 0, "2024-01-02": 0, ... }
		const data = {};
		Object.keys(snackShipments).forEach((item) => {
			Object.keys(snackShipments[item]).forEach((division) => {
				if (division === "合計") {
					return;
				}
				Object.keys(snackShipments[item][division]).forEach((date) => {
					const current = data[date] || 0;
					data[date] = current + snackShipments[item][division][date];
				});
			});
		}
		);
		return groupByWeekOrMonth(data, groupBy);
	}, [snackShipments, groupBy]);
	
	const chartPreds = useMemo(() => {
		// format needs to be { "2024-01-01": 0, "2024-01-02": 0, ... }
		const data = {};
		Object.keys(preds).forEach((pred) => {
			Object.keys(preds[pred]).forEach((division) => {
				if (division === "合計") {
					return;
				}
				Object.keys(preds[pred][division]).forEach((date) => {
					const current = data[date] || 0;
					data[date] = current + preds[pred][division][date];
				});
			}
			);
		});
		return groupByWeekOrMonth(data, groupBy);
	}, [preds, groupBy]);

	
	
	const handleFetch = useCallback(() => {
		const values = form.getFieldsValue();
		const dr = dateRange?.length > 0 ? dateRange.map((d) => d.format("YYYY-MM-DD")) : null
		getSnackShipments2({
			variables: {
				itemNameIds: values.items,
				dateRange: dr,
				groupBy: groupBy,
				division: subdivision,
				selectedDivisions: selectedDivisions,
			},
		});
		getSnackPreds2({
			variables: {
				itemNameIds: values.items,
				dateRange: dr,
				groupBy: groupBy,
				division: subdivision,
				selectedDivisions: selectedDivisions,
			},
		});
	}, [form, dateRange, groupBy, subdivision, selectedDivisions, getSnackShipments2, getSnackPreds2]);
	
	const handleTagChange = (tag, checked) => {
		const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
		setSelectedTags(nextSelectedTags);
	};
	
	const combinedDates = [...new Set([...Object.keys(chartShipments), ...Object.keys(chartPreds)])].sort();
	// item name, prod_ids, division, date, day of week, shipment, pred
	const csvHeader = ['商品名', '製品コード', 
		subdivision === "sales_department_abbreviation" ? '営業部' : 
		subdivision === "branch_name" ? '支店' : 
		subdivision === "primary_accounting_destination_name" ? '帳合先' : '出荷先', 
		'日付', '曜日', '納品数', '予測数'];
	
	const csvRows = (shipments, preds = {}) => {
		 
		// shipments and preds looks like { item_id: { division: { date: count } } }
		// 1 row per item per division per date
		
		const rows = [];
		
		Object.keys(shipments).forEach((item) => {
			Object.keys(shipments[item]).forEach((division) => {
				const itemInfo = allItems.find((i) => i.id === parseInt(item))
				const itemNameAndSize = itemInfo ? `${itemInfo.item_name} --- ${itemInfo.item_size}` : item;
				
				Object.keys(shipments[item][division]).forEach((date) => {
					const day = format(new Date(date), "yyyy-MM-dd");
					const dayOfWeek = format(new Date(date), "EEEE");
					const shipment = shipments[item][division][date];
					const pred = preds[item]?.[division]?.[date] || 0;
					
					rows.push([itemNameAndSize, itemInfo.item_name_prod_id?.most_recent_prod_id, division, day, dayOfWeek, shipment, pred]);
				});
			});
		})
		
		Object.keys(preds).forEach((item) => {
			Object.keys(preds[item]).forEach((division) => {
				const itemInfo = allItems.find((i) => i.id === parseInt(item))
				const itemNameAndSize = itemInfo ? `${itemInfo.item_name} --- ${itemInfo.item_size}` : item;
				
				Object.entries(preds[item][division]).forEach(([date, pred]) => {
					const day = format(new Date(date), "yyyy-MM-dd");
					const dayOfWeek = format(new Date(date), "EEEE");
					const shipment = 0;
					
					if (rows.some((row) => row[0] === itemNameAndSize && row[3] === day && row[2] === division)) {
						return;
					}
					
					rows.push([itemNameAndSize, itemInfo.item_name_prod_id?.most_recent_prod_id, division, day, dayOfWeek, shipment, pred]);
				});
			});
		})
					
					
		// sort rows by item name, division, date
		rows.sort((a, b) => {
			if (a[0] !== b[0]) {
				return parseInt(a[1]) - parseInt(b[1]);
			}
			// use salesDeptSorter
			if (a[2] !== b[2]) {
				return salesDeptSorter([a[2]], [b[2]]);
			}
				
			return a[3].localeCompare(b[3]);
		});
		
		return rows
	};
	
	const generateCSVData = (header, rows) => [header, ...rows];
	
	const handleSnackExport = () => {
		const workbook = XLSX.utils.book_new();
		const csvData = generateCSVData(csvHeader, csvRows(snackShipments, preds));

		// Check if the number of rows exceeds the limit
		if (csvData.length > 999999) {
			Modal.warning({
				title: 'エクセル出力エラー',
				content: 'データ数が100万行を超えました。フィルターの調整をしてデータ数を減らしてください。',
			});
			return;
		}
		const worksheet = XLSX.utils.aoa_to_sheet(csvData, { cellDates: true });
		XLSX.utils.book_append_sheet(workbook, worksheet);
		XLSX.writeFileXLSX(workbook, 'snack_shipments.xlsx')
	}
	
	const rowItemStyle = { marginRight: ".5rem" };
	const rowGutter = [12, 12];

	const hasData = Object.values(snackShipments).some((item) => Object.values(item).length > 0);
	
	const handleSearch = (value) => {
		setSearchText(value);
	}
	
	const addAll = () => {
		form.setFieldsValue({ items: rows.map(item => item.id) });
	}
	
	useEffect(() => {
		handleFetch();
	}, [selectedDivisions, subdivision, handleFetch])
	
	return (
		<Layout style={{ padding: 24, background: "#fff" }}>
			<Row gutter={rowGutter}>
				<Form
					form={form}
					variant="inline"
					style={{ minWidth: "100%" }}
					initialValues={{ items: itemNameId ? [parseInt(itemNameId)] : [] }}
				>
					<Row>
						<Col span={21}>
							<Form.Item label="商品" name="items" style={rowItemStyle} >
								<Select
									showSearch
									mode="multiple"
									onSearch={handleSearch}
									filterOption={false}
									allowClear={true}
									onFocus={() => handleSearch("")}
									onBlur={(e) => {
										if (e.relatedTarget?.id === 'add-all-button') {
											addAll();
										} else {
											handleSearch("")
										}
									}}
									options={rows.map((item) => ({ value: item.id, label: `${item.item_name} --- ${item.item_size}` }))}
									notFoundContent={
										allItemsLoading ? <Spin size="small" /> : allItems.length === 0 ? "No items found" : null
									}
									onChange={(v) => {
										getDivisions({
											variables: {
												itemNameIds: v,
												dateRange: dateRange.length > 0 ? dateRange.map((d) => d.format("YYYY-MM-DD")) : null,
											}
										})
									}}
								/>
							</Form.Item>
							
						</Col>
						<Col span={2}>
							<Form.Item style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
								<Button
									size="small"
									id='add-all-button'
									icon={<PlusCircleOutlined />}
									onClick={() => {
										addAll()
									}}
								>
									全選択
								</Button>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
								<Tooltip placement="top" title="お気に入り商品のみを表示する">
									<Button
										icon={filterFavorites ? <StarFilled /> : <StarOutlined />}
										onClick={() => setFilterFavorites((prev) => !prev)}
										shape="circle"
										disabled={allItems.length === 0}
									/>
								</Tooltip>
							</Form.Item>
						</Col>
						
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item label="営業部">
								<Cascader
									disabled={divisions.length === 0}
									multiple
									style={{ minWidth: "20rem" }}
									options={divisions || []}
									onChange={setSelectedDivisions}
								/>
							</Form.Item>
						</Col>
						
					</Row>
				</Form>
			</Row>

			<Row justify="end" gutter={16}>
				<Col>
					<Form.Item name="deepestDivision">
						<Select onChange={(value) => setSubdivision(value)} defaultValue={"sales_department_abbreviation"}>
							<Select.Option value="sales_department_abbreviation">営業部</Select.Option>
							<Select.Option value="branch_name">支店</Select.Option>
							<Select.Option value="primary_accounting_destination_name">帳合先</Select.Option>
							<Select.Option value="shipping_destination_name_kana">出荷先</Select.Option>
						</Select>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item label="表示期間" name="dateRange">
						<DatePicker.RangePicker disabled={tableGraphMode === "table"} onChange={(value) => {
							setDateRange(value);
						}} />
					</Form.Item>
				</Col>
				<Col>
					<Form form={form}>
						<Form.Item name="groupBy" initialValue="month">
							<Segmented
								options={[
									{
										label: "日",
										value: "day",
									},
									{
										label: "週",
										value: "week",
									},
									{
										label: "月",
										value: "month",
									}
								]}
								disabled={tableGraphMode === "table"}
								onChange={setGroupBy}
							/>
						</Form.Item>
					</Form>
				</Col>
				<Col>
					<Segmented
						options={[
							{ label: "テーブル", value: "table" },
							{ label: "グラフ", value: "graph" },
						]}
						onChange={(v) => {
							setTableGraphMode(v)
							if (v === "table") {
								setGroupBy("month")
							}
						}}
					/>
				</Col>
				<Col>
					<Button icon={<ExportOutlined />} onClick={handleSnackExport} disabled={combinedDates?.length === 0}>
						XLSX出力
					</Button>
				</Col>
				<Col>
					<Button type="primary" htmlType="submit" onClick={() => {
						handleFetch()
					}}>
						{" "}
						アップデート
					</Button>
				</Col>
			</Row>
			<Row gutter={16} justify="start">
				<Col>
					{formattedTags.length > 0 && (
						<Row style={{ marginTop: ".5rem" }}>
							<div style={{ flex: 3, display: "flex", alignItems: "center" }} align="left">
								<span style={{ marginRight: 8 }}>タグ:</span>
								<Space size={[0, 8]} wrap>
									{formattedTags.map((tag) => (
										<Tag.CheckableTag
											style={{
												backgroundColor: selectedTags.includes(tag.id) ? tag.value : "white",
												border: !selectedTags.includes(tag.id) && "1px solid " + tag.value,
												color: selectedTags.includes(tag.id) ? "white" : tag.value,
											}}
											key={tag.id}
											checked={selectedTags.includes(tag.id)}
											onChange={(checked) => handleTagChange(tag.id, checked)}>
											{tag.label}
										</Tag.CheckableTag>
									))}
								</Space>
							</div>
						</Row>
					)}
				</Col>
			</Row>
			<Row style={{ marginTop: "1rem" }}>
				{shipmentsLoading ? (
					<Spin size="large" style={{ margin: "0 auto" }} />
				) : tableGraphMode === "table" ? (
					<>
						{ hasData && (
							<>
							<table style={{ border: "1px solid lightgrey", width: "100%" }}>
								<thead className="tableGrey">
									<tr>
										<th className="leftBorder botBorder" rowSpan="2">
											商品名
										</th>
										<th className="leftBorder botBorder" style={{ minWidth: '15rem'}} rowSpan="2" colSpan="10">
											製品コード
										</th>
										<th
											className="leftBorder botBorder tableMonths"
											style={{ textAlign: "center", padding: ".5rem" }}
											rowSpan={2}>
											グループ
										</th>
										{months.map((month) => (
											<th key={month} className="tableMonths leftBorder" colSpan="3">{`${format(
												month,
												"yyyy-MM"
											)}月`}</th>
										))}
									</tr>
									<tr>
										<th className="leftBorder botBorder" colSpan="3">
											実績
										</th>
										<th className="leftBorder botBorder" colSpan="3">
											実績 / 予測
										</th>
										<th className="leftBorder botBorder" colSpan="3">
											予測
										</th>
										<th className="leftBorder botBorder" colSpan="3">
											予測
										</th>
									</tr>
								</thead>
								<tbody className="tableWhite">
									{Object.keys(snackShipments)
										.sort(itemSorter)
										.map((item) => {
											const itemInfo = allItems.find((i) => i.id === parseInt(item))
											const itemNameAndSize = itemInfo ? `${itemInfo.item_name} --- ${itemInfo.item_size}` : item;
											
											if (Object.keys(snackShipments[item]).length === 0 || !itemInfo) {
                                                return (
                                                    <tr key={item}>
                                                        <td className="tableGrey botBorder" rowSpan={1}>{itemNameAndSize}</td>
                                                        <td className="tableGrey leftBorder botBorder" colSpan="23" rowSpan={1}>No data available</td>
                                                    </tr>
                                                );
                                            }
											
											return Object.keys(tableData[item]).sort(salesDeptSorter)
											.map((division, index) => {
												const mostRecentProdId = itemInfo.item_name_prod_id?.most_recent_prod_id;
												const mapping = itemInfo.item_mappings.find((m) => m.prod_id === mostRecentProdId);
												return (
													<tr key={item + division}>
														{index === 0 && (
															<>
																<td
																	className="tableGrey botBorder"
																	rowSpan={Object.entries(tableData[item]).length}>
																	{itemNameAndSize}
																</td>
																<td
																	className="tableGrey leftBorder botBorder"
																	rowSpan={Object.entries(tableData[item]).length}
																	colSpan="10">
																		<span key={mapping?.prod_id}>
																			{mapping?.prod_id}{" - "}{mapping?.prod_name}<br />
																		</span>
																</td>
															</>
														)}
														<td className="leftBorder botBorder tableWhite">{division}</td>
														{months.map((month, index) => {
															const monthKey = format(month, "yyyy-MM-dd");
															const actualValue = tableData[item][division][monthKey]?.shipment || 0;
															const predsValue = tableData[item][division][monthKey]?.pred || 0;
															
															let monthValue = "";
															if (index === 0) {
																monthValue = actualValue;
															}
															if (index === 1) {
																const totalValue = actualValue + predsValue;
																monthValue = (
																	<>
																		{actualValue} / {predsValue}
																		<br />({totalValue})
																	</>
																);
															}
															if (index >= 2) {
																monthValue = predsValue;
															}
															return (
																<td
																	key={item + month}
																	className="leftBorder botBorder tableWhite"
																	style={{ textAlign: "center" }}
																	colSpan="3">
																	{monthValue}
																</td>
															);
														})}
														</tr>
													);
												});
											})}
									</tbody>
								</table>

								<div style={{ marginTop: "1rem", textAlign: "right" }}>
									<span>単位：ケース数</span>
								</div>
							</>

						)}
					</>
				) : (
					<div style={{ width: "100%", height: "70vh" }}>
						<PredictionsChart
							dateRange={form.getFieldValue("dateRange")}
							predictions={chartPreds}
							nouhins={chartShipments}
							tickValues={form.getFieldValue("groupBy")}
						/>
					</div>
				)}
			</Row>
		</Layout>
	);
};

export default SnackMonthly;